import styled from 'styled-components'

export const CoverArt = styled.img`
  margin: 2rem auto;
  width: 80vw;
  max-width: 37.5rem;
  -moz-box-shadow: 0 0 2rem #111;
  -webkit-box-shadow: 0 0 2rem #111;
  box-shadow: 0 0 2rem #111;
`

export const TrackName = styled.div`
  color: white;
  font-weight: 800;
  font-size: 5rem;
  font-family: Montserrat;
  letter-spacing: 2.4px;
  text-transform: Uppercase;
`

export const Author = styled.div`
  color: white;
  font-weight: 300;
  font-size: 1rem;
  font-family: 'Open Sans';
  text-transform: Uppercase;
`

export const SLink = styled.a`
  margin: 2rem auto;
  padding: 1.5rem 2rem 1.5rem 4.5rem;
  border-radius: 2rem;
  display: block;
  color: white;
  background-color: #1db954;
  font-weight: 800;
  font-size: 1rem;
  width: fit-content;
  font-family: Montserrat;
  letter-spacing: 2.4px;
  text-transform: Uppercase;
  background-image: url('img/spotify-icon.png');
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: 1.5rem center;
  text-decoration: none;
  &:hover {
    color: #111;
    text-decoration: none;
  }
`

export const FullSize = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`
