import { useState, useEffect } from 'react'
import {
  FullSize,
  CoverArt,
  SLink,
  Author,
  TrackName
} from './Style'

declare global {
  interface Window {
     FB: any;
     fbq: any;
  }
}

function App() {

  const pathname = window.location.pathname.replace(/^\/+/g, '')
  const [bg_img, set_bg_img] = useState('')
  const [cv_img, set_cv_img] = useState('')
  const [track_name, set_track_name] = useState('')
  const [track_link, set_track_link] = useState('')

  useEffect(() => {
    switch ( pathname ) {
      case 'tribes':
        // tribes
        set_bg_img('img/bg-tribes.jpeg')
        set_cv_img('img/cv-tribes.jpg')
        set_track_name('Tribes')
        set_track_link('https://open.spotify.com/playlist/1KynHQD73ssVNwD2IHLXL1?si=22c2e9eefdc144cb')
        break
      case 'shackles':
        // shackles
        set_bg_img('img/bg-shackles.jpeg')
        set_cv_img('img/cv-shackles.jpeg')
        set_track_name('Shackles')
        set_track_link('https://open.spotify.com/album/7yHHXTBOzoZFUFJjWY6l72')
        break
      case 'impurity':
        // impurity
        set_bg_img('img/bg-shackles.jpeg')
        set_cv_img('img/cv-shackles.jpeg')
        set_track_name('Impurity')
        set_track_link('https://open.spotify.com/album/7yHHXTBOzoZFUFJjWY6l72')
        break
      default: 
        // none
        set_bg_img('img/bg-shackles.jpeg')
        set_cv_img('img/cv-shackles.jpeg')
        set_track_name('This is Raavn')
        set_track_link('https://open.spotify.com/artist/09tZLjijHa7PbUhfv0Moqh')
        break;
    }
  }, [pathname])

  return (
    <>
      <CoverArt src={cv_img} />
      <TrackName>{track_name}</TrackName>
      <Author>by Raavn</Author>
      <SLink 
      href={track_link} id='conversion-link'
      onClick={e => window.fbq('track', 'Lead')}
      >
        Listen now
      </SLink>
      <FullSize style={{backgroundImage: `url(${bg_img})`}} />
    </>
  );
}

export default App;
